<template>
  <div>
    <loader v-if="loading" />
    <template v-else>

      <div class="d-flex justify-content-end mb-4 margin-guide-not-visible">
        <erase-icon-button
          v-if="removeButtonVisible"
          class="mr-2"
          @click="onDelete"
        />
        <!--        <ib-button-->
        <!--          font-family="regular"-->
        <!--          :disabled="!canEdit"-->
        <!--          @click="onShowAddNewDialog"-->
        <!--        >-->
        <!--          {{ $t('pages.financialPlan.addNewEdit') }}-->
        <!--        </ib-button>-->
      </div>
      <template v-if="!isFinancesEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <financing-sources />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <cash-flow-from-financing-activities />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-2"
        >
          <financing-table />
        </ib-card>
      </template>
      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="financingExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="financing"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Financing -->
    <dialog-add-financing
      v-if="canEdit"
      :dialog-add-financing-visible="dialogAddNewVisible"
      @close-dialog-add-financing="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-financing-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Financing -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import CashFlowFromFinancingActivities from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/CashFlowFromFinancingActivities'
import DialogAddFinancing from '@/views/Home/StoryMode/SetUp/Financing/Dialogs/DialogAddFinancing'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import FinancingSources from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/FinancingSources'
import FinancingTable from '@/views/Home/StoryMode/SetUp/Financing/Tables/FinancingTable'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapState } from 'vuex'

export default {
  name: 'Financing',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    CashFlowFromFinancingActivities,
    DialogAddFinancing,
    EmptyPlaceholder,
    EraseIconButton,
    FinancingSources,
    FinancingTable,
    Loader
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false,
      guideVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    isFinancesEmpty () {
      return this.idea.storyMode.setUp.financing.finances.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.financesHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('financingFinancialPlanGuide')

    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
  },

  methods: {
    onShowAddNewDialog () {
      this.getFinancing()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getFinancing(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('financing')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
